@use "assets/styles" as *;

div.order-confirmation-wrapper {
  height: calc(100vh - #{$header-height});
  position: relative;
  // This makes scrollIntoView go all the way to the top
  scroll-margin-top: $header-height;

  &.in-mobile-app {
    height: 100vh;
  }

  div.scroll-button {
    position: fixed;
    bottom: rem(10);
    left: rem(20);
    width: rem(50);
    height: rem(50);
    background-color: $sheetz-red;
    border: rem(2) solid $primary-dark-gray;
    border-radius: rem(50);
    display: none;
    transition: all 5s ease-in-out;

    button {
      display: flex;
      justify-content: center;

      svg {
        margin-top: rem(5);
      }
    }
  }

  div.my-order-wrapper {
    @include order-confirmation-desktop {
      margin-top: rem(40);
    }

    div.order-totals-wrapper {
      padding: 0 rem(20) rem(64);
    }
  }

  div.order-confirmation-scroll-container {
    position: relative;
    overflow-y: scroll;
    height: calc(100% - #{$payment-footer-height});

    &.delivery {
      height: calc(100% - #{$payment-footer-delivery-height});
    }

    &.pay-in-store {
      height: calc(100% - #{$payment-footer-in-store-height});
    }

    @include order-confirmation-desktop {
      display: flex;
      overflow: initial;
      height: calc(100vh - #{$header-height});
      z-index: $order-confirmation-fixed-index;

      &.delivery,
      &.pay-in-store {
        height: calc(100vh - #{$header-height});
      }
    }

    div.order-confirmation-content {
      @include order-confirmation-desktop {
        width: rem(424);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow-y: auto;
      }

      div.order-confirmation-customer-information {
        padding: rem(32) rem(20) rem(18);
        background-color: $body-bg;

        @include order-confirmation-desktop {
          padding: rem(20);
          background-color: transparent;
        }
      }

      div.total-savings-bar-desktop {
        margin: rem(40) 0;

        div.total-savings-bar-content {
          height: rem(60);
        }
      }

      div.payment-footer-container {
        padding: rem(20) 0;
        width: rem(424);

        &.absolute {
          position: fixed;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: $payment-footer-index;
          padding: 0;
          width: auto;
        }

        // This fixes a webview defect that in iOS, when the payment footer expands
        // due to and async error and the height was already set, the z-index is thrown out
        // but only the extra expanded space loses it and the scrollable area underneath shows on top.
        &.in-mobile-app {
          transform: translate3d(0, 0, 1px);
        }
      }
    }
  }
}

// This is a dummy container to keep the background color of the sidebar in desktop mode
div.order-confirmation-grid {
  @include order-confirmation-desktop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: grid;
    grid-template-columns: auto rem(424);
  }

  div.order-confirmation-right {
    @include order-confirmation-desktop {
      background-color: $primary-dark-gray;
    }
  }
}